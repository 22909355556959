import { defineStore, storeToRefs } from 'pinia'
import { useGameStore } from '~/store/game'
import { useTranslationStore } from '~/store/translations'

type ModalStore = {
    active: boolean,
    text: string,
    buttonText: string,
    buttonLink?: string,
    action: () => void,
    rules?: boolean
}

export enum ModalType {
    balanceError,
    auth,
    unexpectedError,
    shortRules
}


export const useModal = defineStore("modal", {
    state: (): ModalStore => ({
        active: false,
        text: "",
        buttonText: "",
        buttonLink: undefined,
        rules: undefined,
        action: () => {}
    }),

    actions: {
        show(type: ModalType) {
            const game = useGameStore();
            switch (type) {
                case ModalType.auth: {
                    this.text = "Зарегистрируйтесь для покупки билета";
                    this.buttonText = "Регистрация";
                    this.buttonLink = game.links.authorization_url;
                    this.active = true;
                    this.action = () => {}
                    this.rules = false;
                    break;
                }

                case ModalType.balanceError: {
                    const {app_insufficient_funds, app_top_up} = storeToRefs(useTranslationStore());
                    this.text = app_insufficient_funds.value;
                    this.buttonText = app_top_up.value;
                    this.buttonLink = game.links.wallet_url;
                    this.active = true;
                    this.action = () => {}
                    this.rules = false;

                    break;
                }

                case ModalType.unexpectedError: {
                    this.text = "Что-то пошло не так. Попробуйте ещё раз."
                    this.buttonText = "В игру."
                    this.buttonLink = undefined;
                    this.active = true;
                    this.action = () => {
                        this.active = false;
                    }
                    this.rules = false;
                    break;
                }

                case ModalType.shortRules: {
                    this.text = "";

                    this.buttonText = "Выбрать сундук";
                    this.buttonLink = undefined;
                    this.active = true;
                    this.action = () => {
                        this.active = false;
                    }
                    this.rules = true;
                    break;
                }


                default: {
                    throw new Error("modal undefined")
                }
            }
        }
    }
})
