import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'start',
    component: () => import('./StartRoute.vue'),
  },
  {
    path: '/chest',
    name: 'chest',
    component: () => import('./ChestRoute.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
