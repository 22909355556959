import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'

type OnboardingState = {
  price: Ref<HTMLElement | undefined>,
  prize: Ref<HTMLElement | undefined>,
  chest: Ref<HTMLElement | undefined>,
  swipeL: Ref<HTMLElement | undefined>,
  swipeR: Ref<HTMLElement | undefined>,
  active: boolean,
  onStart: boolean,
  prizeHover?: boolean,
  priceHover?: boolean,
  chestHover?: boolean,
  swipeLHover?: boolean,
  swipeRHover?: boolean,
  page: number
}

export const useOnboardingStore = defineStore("onboarding", {
  state: (): OnboardingState => ({
    price: ref(undefined),
    prize: ref(undefined),
    chest: ref(undefined),
    swipeL: ref(undefined),
    swipeR: ref(undefined),
    active: false,
    onStart: true,
    priceHover: undefined,
    prizeHover: undefined,
    chestHover: undefined,
    swipeLHover: undefined,
    swipeRHover: undefined,
    page: 1,
  }),
  actions: {
    changePage(n?: number): void {
      if(n) {
        this.page = n;
      } else {
        if (this.page < 4) {
          this.page++;
        } else {
          this.onStart = false;
          this.active = false;
          this.page = 1;
        }
      }
    }
  }
});
