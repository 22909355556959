import { axiosInstance } from './axiosConfig'
import { Translations } from '~/store'
import { SessionResponse } from '~/api/types'

export const getSessionInfo = (): Promise<SessionResponse> => {
  return axiosInstance.get<SessionResponse>('/games/session/info').catch(e => {
    console.log(e)
  }).then(res => {
    if (res) {
      return res.data
    } else {
      throw new Error('session info load error')
    }
  })
}

export const getTranslations = (): Promise<Translations> => {
  return axiosInstance.get<Translations>('/translations/all').catch(e => {
    console.log(e)
  }).then(res => {
    if (res) {
      return res.data
    } else {
      throw new Error('translations load error')
    }
  })
}


export const buyChest = ({ cost, chestId }: { cost: number, chestId: number }): Promise<number | null > => {
  return axiosInstance.post<{ id: number | null }>('/games/buy', {
      purchase_type: 'purse',
      cost: cost,
      chest_id: chestId
    },
    {
      validateStatus: function (status) {
        return !!status
      }
    }).catch(e => {
    console.log(e)
  }).then(res => {
    if (res) {
      return res.data.id;
    } else {
      return null;
    }
  })
}

export const checkTicket = (id: number): Promise<any> => {
  return axiosInstance.get<{ status: number, total_amount: number, error_code?: string }>(`/games/tickets/${id}`).catch(e => {
    console.log(e)
  }).then(res => {
    if (res) {
      return [res.data.status, res.data.total_amount, res.data.error_code]
    } else {
      throw new Error('ticket check error')
    }
  })
}

export const fetchSound = (name: string): Promise<ArrayBuffer> => {
  return fetch(`/sound/${name}.mp3`).then(res => res.arrayBuffer()).catch(e => {
    throw new Error(e);
  })
}

export const fetchAllSound = (): Promise<[ArrayBuffer, ArrayBuffer, ArrayBuffer, ArrayBuffer]> => {
  return Promise.all([
    fetchSound('open'),
    fetchSound('shaking'),
    fetchSound('click-push'),
    fetchSound('lucky-chest-theme')
  ])
}
