import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { useGameStore } from '~/store';


const initGame = async () => {


    const pinia = createPinia();

    let appV = createApp(App)
        .use(router)
        .use(pinia);

    await useGameStore().init();

    appV.mount('#app');

};

initGame();
