import { defineStore } from 'pinia'
import { getTranslations } from '~/api'


export type Translations = Record<string, string>;

export const useTranslationStore = defineStore('translations', {
  state: (): Translations  => ({
      app_start: "Начать игру",
      app_change: "сменить",
      app_open: "открыть",
      app_main_prize: "главный приз",
      app_cost: "билет",
      app_top_up: "пополнить",
      app_insufficient_funds: "нехватает баланса",
      app_rules: "Полные правила",
      app_onboarding_prize: "Это максимальный выигрыш, сумма зависит от ранга сундука.",
      app_onboarding_price: "Здесь показывается стоимость одной игры.",
      app_onboarding_change: "Свайпай влево, чтобы изменить ранг сундука. ",
      app_onboarding_open: "Свайпай вправо, чтобы открыть сундук.",
    }),
  actions: {
    async getFromApi(): Promise<void> {
      this.$state = await getTranslations();
    }
  }
})

