import { defineStore } from 'pinia'
import { buyChest, checkTicket, Chest, getSessionInfo } from '~/api'
import { useTranslationStore } from './translations'
import { ModalType, useModal } from '~/store/modal'
import { useOnboardingStore } from '~/store/onboarding'
import { useSound } from '~/store/sound'

type GameStore = {
    token: string,
    no_auth: boolean,
    chests: Chest[],
    currentChest?: Chest,
    nextChest?: Chest,
    animationType: 'open-close' | 'shaking-continue' | 'shaking-start' | 'waiting',
    animationLoop: boolean,
    winAmount: number | undefined,
    rotation: boolean,
    rules: string,
    links: {
        authorization_url: string,
        rules_url: string,
        wallet_url: string,
        platform_url: string
    },
    buyProcess: boolean
}


export const useGameStore = defineStore('game', {
    state: (): GameStore => ({
        token: '',
        no_auth: true,
        chests: [],
        currentChest: undefined,
        nextChest: undefined,
        animationType: 'waiting',
        animationLoop: false,
        winAmount: undefined,
        rules: "",
        rotation: false,
        links: {
            authorization_url: "",
            rules_url: "",
            wallet_url: "",
            platform_url: ""
        },
        buyProcess: false
    }),

    actions: {

        getToken(): void {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            this.token = params.session;
        },

        async init(): Promise<void> {
            const onboarding = useOnboardingStore();
            const translations = useTranslationStore();
            const sound = useSound()

            // Берём токен и с его помощью запрашиваем данные сессии, правила и переводы.
            this.getToken();


            const [ sessionInfo ] = await Promise.all([ getSessionInfo(), translations.getFromApi(), sound.init()]);

            this.no_auth = sessionInfo.result.no_auth;

            this.rules = sessionInfo.rules[0].text;

            onboarding.$patch({onStart: sessionInfo.result.first_time, active: sessionInfo.result.first_time});

            this.links = sessionInfo.parameters;

            this.chests = sessionInfo.chests;

            this.currentChest = this.chests[0];

            this.nextChest = this.chests[1];
        },

        async buy(): Promise<void> {
            if (!this.currentChest) {
                throw new Error('current chest undefined');
            }
            const modalStore = useModal();


            this.buyProcess = true;
            this.animationLoop = true;
            this.animationType = 'shaking-continue';
            const res = await buyChest({ cost: +this.currentChest.ticket_cost, chestId: this.currentChest.id });
            if (typeof res === 'number') {
                for(let i = 0; i < 5; i++) {
                    const [ status, total_amount, error ] = await checkTicket(res);
                    if (status === 4 && error == "SOMETHING_WRONG") {
                        modalStore.show(ModalType.balanceError);
                        this.animationType = "waiting";
                        break;
                    }
                    if (status === 3) {
                        this.winAmount = total_amount;
                        this.animationType = 'open-close';
                        break;
                    } else if ( i == 4) {
                        this.animationType = "waiting";
                        modalStore.show(ModalType.unexpectedError);
                    }
                }
            } else {
                modalStore.show(ModalType.unexpectedError);
                this.animationType = 'waiting';
            }
            this.animationLoop = false;

            setTimeout(() => {
                this.buyProcess = false;
            }, 1400);

        },

        changeChest(): void {
            this.rotation = true;
            if (!this.currentChest) {
                throw new Error('current chest undefined');
            }
            if (!this.nextChest) {
                throw new Error('next chest undefined');
            }

            if (this.currentChest.id == this.chests.length) {
                this.currentChest = this.chests[0];
                this.nextChest = this.chests[1];
                return;
            }

            this.currentChest = this.chests[this.currentChest.id]; // id на 1 больше, чем индекс => возьмётся следующий сундук.
            if (this.nextChest.id == this.chests.length) {
                this.nextChest = this.chests[0]
            } else {
                this.nextChest = this.chests[this.nextChest.id];
            }

        }
    }
});


