import { defineStore } from 'pinia'
import { fetchAllSound } from '~/api'
// @ts-ignore
import { Howl } from "howler"

type Sound = {
  backgroundSound: Howl,
  active: boolean,
  swipeSound: Howl,
  openSound: Howl,
  shakingSound: Howl
}

export const useSound = defineStore('sound', {
  state: (): Sound => ({
    backgroundSound: new Howl({
      src: ["/sound/lucky-chest-theme.mp3"],
      loop: true,
    }),

    active: false,

    swipeSound: new Howl({
      src: ["/sound/click-push.mp3"],
    }),

    openSound: new Howl({
      src: ["/sound/open.mp3"]
    }),

    shakingSound: new Howl({
      src: ["/sound/shaking.mp3"]
    })

  }),
  actions: {
    async init() {
      try{
        // @ts-ignore
        const ctx: AudioContext = window.audioContext;

        window.onblur = this.turnOffBg;
        window.onfocus = this.turnOnBg;

      } catch (e) {
        console.log(e);
      }

    },
    async turnOnBg () {
      if (this.backgroundSound && this.active) {
        this.backgroundSound.play();
      }

    },
    async turnOffBg () {
      if (this.backgroundSound) {
        this.backgroundSound.pause();
      }

    },
    swipe () {
      if (this.active && this.swipeSound) {
        this.swipeSound.play();
      }
    },
    open () {
      if (this.active && this.openSound) {
        this.openSound.play();
      }
    },
    shaking() {
      if (this.active && this.shakingSound) {
        this.shakingSound.play();
      }
    }
  }
})
