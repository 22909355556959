import axios from 'axios'
import config from './config'
import { useGameStore } from '~/store'
import { storeToRefs } from 'pinia'

export const axiosInstance = axios.create({
  baseURL: config.url.backend + config.url.api,
  timeout: config.requestTimeout,

})

axiosInstance.interceptors.request.use(config => {
  const gameStore = useGameStore()
  const { token } = storeToRefs(gameStore)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  if (token) {
    config.headers!.token = token.value
  } else if (params.session) {
    config.headers!.token = params.session
  }

  return config
})
